import cogoToast from "cogo-toast";
import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { UserClient } from "../../api";

class Header extends Component {
  userId = 0;
  userClient: UserClient = new UserClient();
  hasPicture: boolean = false;
  constructor(props: any) {
    super(props);
    var userDataJSON = localStorage.getItem("UserData");
    if (userDataJSON) {
      var userData = JSON.parse(userDataJSON);
      this.userId = userData.userId.value;
      this.hasPicture = userData.hasPicture;
    }
  }
  doLogout = (e: any) => {
    e.preventDefault();

    console.log("Logging out...");
    this.userClient
      .logout()
      .then((response) => {
        console.log("Logout response", response);
        localStorage.removeItem("UserData");
        window.location.href = "/user-pages/login-1";
      })
      .catch((error) => {
        cogoToast.error("Failed to logout: " + error);
        console.error("Error fetching data: ", error);
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  render() {
    return (
      <div className="horizontal-menu">
        <nav className="navbar top-navbar default-layout-navbar col-lg-12 col-12 p-0 d-flex flex-row">
          <div className="container">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              <Link className="navbar-brand brand-logo" to="/">
                <h3>Car Lewis</h3>
              </Link>
              <Link className="navbar-brand brand-logo-mini" to="/">
                <h3>Car Lewis</h3>
              </Link>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
              <ul className="navbar-nav navbar-nav-right">
                <Dropdown alignRight as="li" className="nav-item border-left">
                  <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer">
                    <div className="navbar-profile">
                      {this.hasPicture ? (
                        <img className="img-xs rounded-circle" src={process.env.REACT_APP_API_URL + "/User/GetProfilePicture/" + this.userId} alt="profile" />
                      ) : (
                        <img src={require("../../assets/images/faces-clipart/pic-4.png").default} alt="profile" className="img-xs rounded-circle" />
                      )}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                    <Dropdown.Item className="dropdown-item preview-item" href="/user-pages/profile">
                      <i className="mdi mdi-account menu-icon"></i>
                      <span className="menu-title">Profile</span>
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-item preview-item" onClick={this.doLogout}>
                      <i className="mdi mdi-logout menu-icon"></i>
                      <span className="menu-title">Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
              <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleBottomMenu}>
                <span className="ti-menu"></span>
              </button>
            </div>
          </div>
        </nav>
        <nav className="bottom-navbar">
          <div className="container">
            <ul className="nav page-navigation">
              <li className={this.isPathActive("/dashboard") ? "nav-item menu-items active" : "nav-item menu-items"}>
                <Link className="nav-link" to="/dashboard">
                  <i className="mdi mdi-speedometer menu-icon"></i>
                  <span className="menu-title">Dashboard</span>
                </Link>
              </li>
              <li className={this.isPathActive("/Cars") ? "nav-item menu-items active" : "nav-item menu-items"}>
                <Link className="nav-link" to="/Cars">
                  <i className="mdi mdi-car menu-icon"></i>
                  <span className="menu-title">Cars</span>
                </Link>
              </li>
              <li className={this.isPathActive("/Services") ? "nav-item menu-items active" : "nav-item menu-items"}>
                <Link className="nav-link" to="/Services">
                  <i className="mdi mdi-wrench menu-icon"></i>
                  <span className="menu-title">Services</span>
                </Link>
              </li>
              <li className={this.isPathActive("/gas") ? "nav-item menu-items active" : "nav-item menu-items"}>
                <Link className="nav-link" to="/gas">
                  <i className="mdi mdi-gas-station menu-icon"></i>
                  <span className="menu-title">Gas</span>
                </Link>
              </li>
              <li className={this.isPathActive("/documents") ? "nav-item menu-items active" : "nav-item menu-items"}>
                <Link className="nav-link" to="/documents">
                  <i className="mdi mdi-file-document menu-icon"></i>
                  <span className="menu-title">Documents</span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }

  toggleBottomMenu() {
    (document.querySelector(".bottom-navbar") as any).classList.toggle("header-toggled");
  }

  toggleRightSidebar() {
    (document.querySelector(".right-sidebar") as any).classList.toggle("open");
  }

  componentDidMount = () => {
    // Horizontal menu fixed when scrolling
    let navbar = document.querySelector(".horizontal-menu");
    let body = document.querySelector("body");
    if (navbar) {
      window.addEventListener("scroll", function () {
        if (window.scrollY >= 70) {
          navbar?.classList.add("fixed-on-scroll");
          body?.classList.add("horizontal-menu-fixed-on-scroll");
        } else {
          navbar?.classList.remove("fixed-on-scroll");
          body?.classList.remove("horizontal-menu-fixed-on-scroll");
        }
      });
    }

    // Horizontal menu navigation in mobile menu on click
    let navItemClicked = document.querySelectorAll(".horizontal-menu .page-navigation >.nav-item");
    navItemClicked.forEach((el: any) => {
      el.addEventListener("click", () => {
        var result = [],
          node = (this as any).parentNode?.firstChild;
        while (node) {
          if (node !== this) result.push(node);
          node = node.nextElementSibling || node.nextSibling;
        }
        result.forEach((el) => el.classList.remove("show-submenu"));
        (this as any).classList?.toggle("show-submenu");
      });
    });
  };

  isPathActive(path: any) {
    return (this.props as any).location.pathname.startsWith(path);
  }
}

export default withRouter(Header as any) as any;
