import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Header from "./shared/Header";
import Footer from "./shared/Footer";

class App extends Component {
  props: any;
  constructor(props: any) {
    super(props);

    this.state = {
      isFullPageLayout: false,
      user: {
        userId: 0,
        name: "",
      },
    };
  }

  handleUserChange = (user: any) => {
    console.log("App.js handleUserChange: ", user);
    this.setState({
      user: {
        userId: 0,
        name: user.name,
      },
    });
  };
  state = {
    isFullPageLayout: false,
    user: {
      userId: 0,
      name: "",
    },
  };
  componentDidMount() {
    this.onRouteChanged();

    var userData: any = localStorage.getItem("UserData");
    if (userData) {
      this.setState({
        user: {
          userId: userData?.userId?.value,
          name: userData?.name,
        },
      });
    }
  }
  render() {
    let user = this.state.user;
    let headerComponent = !this.state.isFullPageLayout ? <Header user={user} history={this.props.history} onUserChange={this.handleUserChange} /> : "";
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    return (
      <div className="container-scroller">
        {headerComponent}
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes user={user} history={this.props.history} onUserChange={this.handleUserChange} />
            </div>
            {footerComponent}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/user-pages/login-1",
      "/user-pages/login-2",
      "/user-pages/register-1",
      "/user-pages/register-2",
      "/user-pages/lockscreen",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
      "/user-pages/resetPassword",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i] || (this.props.location.pathname as string).indexOf("/user-pages/resetPassword") >= 0) {
        this.setState({
          isFullPageLayout: true,
        });
        document.querySelector(".page-body-wrapper")?.classList.add("full-page-wrapper");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document.querySelector(".page-body-wrapper")?.classList.remove("full-page-wrapper");
      }
    }
  }
}

export default withRouter(App);
