import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class Footer extends Component {
  render () {
    return (
      <footer className="footer container">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Template: <a href="https://www.bootstrapdash.com/demo/corona/react/template/demo_5/preview/dashboard" target="_blank">Corona Bootstrap Dash</a></span>
          <span className="text-muted float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
        </div>
      </footer>
    );
  }
}

export default Footer;